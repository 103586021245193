import { DeleteAction, GetAction, PostAction, PutAction, UpdateAction } from "../actionHelper";
import LC from "../../common/storage/localStorage";

// [NAVIGATION ACTIONS]
export const GET_FILM = 'getFilm'; //
export const GET_FILM_DETAIL = 'getFilmDetail'; //
export const GET_FILMS = 'getFilms'; //
export const STORE_FILM = 'storeFilm'; //
export const UPDATE_FILM = 'updateFilm'; //
export const SEARCH_FILM = 'searchFilm'; //
export const GET_FILM_POSTER = 'getFilmPoster'; //
export const UPDATE_FILM_POSTER = 'updateFilmPoster'; //
export const PROCESSED_INVALID_FILM = 'processedInvalidFilm'; //
export const PUSH_UP_FILM = 'pushUpFilm'; //
export const CANCEL_PUSH_UP_FILM = 'cancelPushUpFilm'; //
export const GET_INVALID_FILMS = 'getInvalidFilms'; //
export const DELETE_BATCH_FILM = 'deleteBathFilm'; //

// [NAVIGATION MUTATIONS]
export const SET_FILM = 'setFilm'; //
export const SET_FILM_DETAIL = 'setFilmDetail'; //
export const SET_FILMS = 'setFilms'; //
export const SET_FILM_ERROR = 'setFilmError'; //
export const SET_SEARCH_FILM_LIST = 'setSearchFilmList';
export const SET_FILM_POSTER = 'setFilmPoster'; //
export const SET_FILM_PAGE = 'setFilmPage'; //
export const SET_INVALID_FILMS = 'setInvalidFilms'; //

const state = {
  film: {},
  filmDetail: {},
  films: [],
  filmsMeta: {},
  filmError: null,
  searchFilmList: [],
  searchFilmListMeta: {},
  filmPoster: {},
  filmPage: 1,
  invalids: [],
  invalidsMeta: {}
};

const mutations = {
  [SET_FILM](state, payload){
    state.film = payload;
  },
  [SET_FILM_DETAIL](state, payload){
    state.filmDetail = payload;
  },
  [SET_FILMS](state, payload){
    state.films = payload.data;
    state.filmsMeta = payload.meta;
  },
  [SET_FILM_ERROR](state, payload){
    state.filmError = payload;
  },
  [SET_SEARCH_FILM_LIST](state, payload){
    state.searchFilmList = payload.data;
    state.searchFilmListMeta = payload.meta;
  },
  [SET_FILM_POSTER](state, payload){
    state.filmPoster = payload;
  },
  [SET_FILM_PAGE](state, payload){
    state.filmPage = payload;
  },
  [SET_INVALID_FILMS](state, payload){
    state.invalids = payload.data;
    state.invalidsMeta = payload.meta;
  }
};

const actions = {
  [GET_FILM]( context, id ) {
    return GetAction( `manager/films/${id}`,
    ``,
    context,
    SET_FILM_ERROR, {
      credentials: true, resolved: ( context, response ) => {
        let { data } = response;
        context.commit( SET_FILM, data );
        LC.set(`film_${data.id}`);
      }
    } );
  },
  [GET_FILM_DETAIL]( context, {id, payload = {}} ) {
    context.commit( SET_FILM_DETAIL, {} );
    return PostAction( `manager/films/${id}/detail`,
    payload,
    context,
    SET_FILM_ERROR, {
      credentials: true, resolved: ( context, response ) => {
        let { data } = response;
        context.commit( SET_FILM_DETAIL, data );
        LC.set(`film_${id}_detail`);
      }
    } );
  },
  [GET_FILMS]( context, { page= 1, title= '' } ) {
    return GetAction( "manager/films",
    `?title=${title || ''}&page=${page || 1}`,
    context,
    SET_FILM_ERROR, {
      credentials: true, resolved: ( context, response ) => {
        context.commit( SET_FILMS, response.data );
        context.commit(SET_FILM_PAGE, page);
      }
    } );
  },
  [STORE_FILM]( context, payload = {} ) {
    return PostAction(
    "manager/films", payload, context, SET_FILM_ERROR,
    { credentials: true }
    );
  },
  [UPDATE_FILM]( context, payload = {} ) {
    return UpdateAction(
    "manager/films", payload.id, payload, context, SET_FILM_ERROR,
    { credentials: true,
      resolved: ( context, response ) => {
        let { data } = response;
        context.commit( SET_FILM, data );
        LC.set(`film_${data.id}`);
      }
    }
    );
  },
  [SEARCH_FILM]( context, { page= '', title= '' } ) {
    return GetAction( "manager/films/search",
    `?title=${title || ''}${page ? '&page=' + page : ''}${page ? '&paginate=' + page : ''}`,
    context,
    SET_FILM_ERROR, {
      credentials: true, resolved: ( context, response ) => {
        context.commit( SET_SEARCH_FILM_LIST, response.data );
      }
    } );
  },
  [GET_FILM_POSTER]( context, belongs = 'mp' ) {
    return GetAction( `manager/films/poster?belongs=${belongs}`,
    ``,
    context,
    SET_FILM_ERROR, {
      credentials: true, resolved: ( context, response ) => {
        let { data } = response;
        context.commit( SET_FILM_POSTER, data );
      }
    } );
  },
  [UPDATE_FILM_POSTER]( context, payload = {} ) {
    return PostAction(
    "manager/films/poster", payload, context, SET_FILM_ERROR,
    { credentials: true,
      resolved: ( context, response ) => {
        let { data } = response;
        context.commit( SET_FILM_POSTER, data );
      }
    }
    );
  },
  [PUSH_UP_FILM]( context, id ) {
    return PutAction(
    `manager/films/${id}/push-up`, {}, context, SET_FILM_ERROR,
    { credentials: true }
    );
  },
  [CANCEL_PUSH_UP_FILM]( context, id ) {
    return PutAction(
    `manager/films/${id}/cancel-push-up`, {}, context, SET_FILM_ERROR,
    { credentials: true }
    );
  },
  [GET_INVALID_FILMS]( context, { page= 1 } ) {
    return GetAction( "manager/films/invalid",
    `?page=${page}`,
    context,
    SET_FILM_ERROR, {
      credentials: true, resolved: ( context, response ) => {
        context.commit( SET_INVALID_FILMS, response.data );
        // context.commit(SET_FILM_PAGE, page);
      }
    } );
  },
  [PROCESSED_INVALID_FILM]( context, id ) {
    return PutAction(
    `manager/films/invalid/${id}/processed`, {}, context, SET_FILM_ERROR,
    { credentials: true }
    );
  },
  [DELETE_BATCH_FILM]( context, id ) {
    return PostAction(
    `manager/films/batch-delete`, {id}, context, SET_FILM_ERROR,
    { credentials: true }
    );
  },
};

const getters = {
  film: state=> state.film,
  filmDetail: state=> state.filmDetail,
  films: state=> state.films,
  filmsMeta: state=> state.filmsMeta,
  filmError: state=> state.filmError,
  searchFilmList: state=> state.searchFilmList,
  searchFilmListMeta: state=> state.searchFilmListMeta,
  filmPoster: state=> state.filmPoster,
  filmPage: state=> state.filmPage,
  invalids: state=> state.invalids,
  invalidsMeta: state=> state.invalidsMeta,
};

export default {
  state,
  mutations,
  actions,
  getters
}
